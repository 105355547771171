import * as React from 'react'
import Layout from '../@lekoarts/gatsby-theme-cara/components/layout'
import { Box } from 'theme-ui'
import Footer from '../@lekoarts/gatsby-theme-cara/components/footer'
import HeroOurBusiness from '../@lekoarts/gatsby-theme-cara/components/OurBusiness/heroOurbusiness'
import { HistoryBusiness } from '../@lekoarts/gatsby-theme-cara/components/OurBusiness/historyBusiness'

const OurBusiness = () => (
  <Layout>
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        overflow: 'hidden scroll',
        transform: 'translate3d(0px, 0px, 0px)',
      }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          position: 'absolute',
          width: '100%',
          height: '550vh',
          transform: 'translate3d(0px, 0px, 0px)',
        }}
      >
        <HeroOurBusiness height="100dvh" />
        <HistoryBusiness top="100dvh" height="450vh" />
        <Footer />
      </Box>
    </Box>
  </Layout>
)

export default OurBusiness
